import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {AppContextProvider} from "./context/app";
import {App} from "./containers";

export default function Index() {
  return (
    <AppContextProvider>
      <App />
    </AppContextProvider>
  );
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(<Index />);
