
export async function runStreamingChatCompletion (apiPromise: Promise<any>, props: {
  setStream: (chnk: string) => void; // function to be called on each chunk
  stepFunc: (cnt: string, done?: boolean) => void; // function to be called every time on the full content so far
}) {
  const { setStream, stepFunc } = props;
  let cnt = "";
  let counter = 0;
  apiPromise
    .then((response) => {
      const reader = response.body?.getReader();

      function processChunk() {
        reader
          .read()
          .then(({ done, value }) => {
            if (done) {
              console.log("Stream complete");
              stepFunc(cnt, true);
              setStream("[done]");
              return;
            }

            const chnk = new TextDecoder("utf-8").decode(value);
            cnt += chnk;
            setStream(chnk);
            if (cnt.length - counter > 20) {
              counter = cnt.length;
              stepFunc(cnt);
            }

            processChunk();
          })
          .catch((err) => {
            console.error("Stream reading error:", err);
          });
      }

      processChunk();
    })
    .catch((err) => {
      console.error("Fetch error:", err);
    });
}

export async function runStreamingChatCompletionJSON(apiPromise: Promise<any>, props: {
  setStream: (chnk: string) => void; // function to be called on each chunk
  stepFunc: (cnt: string, done?: boolean) => void; // function to be called every time on the full content so far
}) {
  const { setStream, stepFunc } = props;
  let cnt = "";
  let counter = 0;
  apiPromise
    .then((response) => {
      const reader = response.body.getReader();

      function processChunk() {
        reader
          .read()
          .then(({ done, value }) => {
            if (done) {
              console.log("Stream complete");
              const ind = cnt.lastIndexOf("}");
              cnt = cnt.substring(0, ind + 1);
              stepFunc(cnt, true);
              setStream("[done]");
              //setFullPlan1(cnt)
              return;
            }

            // Log each chunk
            const chnk = new TextDecoder("utf-8").decode(value);
            cnt += chnk;
            setStream(chnk);
            if (cnt.length - counter > 20) {
              if (!cnt.startsWith("{")) {
                const ind = cnt.indexOf("{");
                if (ind > 0) {
                  cnt = cnt.substring(ind);
                }
              }
              counter = cnt.length;
              stepFunc(cnt);
            }

            // Recursively call processChunk to read the next chunk
            processChunk();
          })
          .catch((err) => {
            console.error("Stream reading error:", err);
          });
      }

      processChunk();
    })
    .catch((err) => {
      console.error("Fetch error:", err);
    });
}