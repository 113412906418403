import React, {useEffect, useState} from "react";
import {GeneratorItem} from "../components/GeneratorItem";
import {useAppContext, apiCall, ROOM_TITLE_KEY} from "../context/app";
import {runStreamingChatCompletion, runStreamingChatCompletionJSON} from "../api/integrail";
import {partialParse} from "../utils/partial-json";
import client from "../api/socket";
import { pushDataLayer } from "../utils/ga";

export interface IGeneratorProps {
  // onContent: (data: { title: string; content: string; }) => void;
}

export function Generator ({
  // onContent,
}: IGeneratorProps) {
  const appContext = useAppContext();
  // const [allContent, setAllContent] = useState("");

  const [stream, setRawStream] = useState("");
  const setStream = (value, ...args) => {
    // @ts-ignore

    const v = (value || '').replace('[done]', '...').replace(/[^a-zA-Z0-9\s]/g, '');

    const rawStreamArgs = [v, ...args];
    // @ts-ignore
    // onContent(prev => {
    //   if (prev.content && prev.content.length > 4 && prev.content.slice(-3) === '...') {
    //     console.warn('V includes ....',prev.content, v);
    //     return prev;
    //   }

    //   if (v.includes('\n')) {
    //     console.warn('includes \n');
    //     return { title: prev.title, content: `${prev.content}${v}...`};
    //   }

    //   return ({ title: prev.title, content: `${prev.content}${v}` })
    // });
    setRawStream.apply(this, rawStreamArgs);
  }

  const [curState, setCurState] = useState({
    logo: true,
    summary: true,
    swot: true,
    oplan: true,
    room: true,
  });

  const [started, setStarted] = useState(false);
  
  useEffect(() => {
    function handleError(msg: string) {
      pushDataLayer({
        event: "swot.error",
        id: appContext.analyticsId,
        message: msg,
      });

      appContext.registerForm.setErrors({general: msg});
      appContext.setRoute('auth');
    }

    async function run() {
      try {
        // console.warn('LINKDATA', appContext.linkData);
        // logo { linkData }
        // onContent({ title: 'logo', content: '' });

        await runStreamingChatCompletion(apiCall('/logo', {linkData: appContext.linkData}, false), {
          setStream: setStream,
          stepFunc: async (cnt, done) => {
            // console.log('/logo done', done);
            try {
              if (done === true) {
                // console.warn('LOGO', cnt);
                setCurState(prev => ({...prev, logo: false}));

                // onContent({ title: 'summary', content: '' });

                await runStreamingChatCompletion(apiCall('/summary', {linkData: appContext.linkData}, false), {
                  setStream: setStream,
                  stepFunc: async (productcnt, done) => {
                    // console.log('/summary done', done);
                    try {
                      if (done === true) {
                        // console.warn('Summary', productcnt);
                        setCurState(prev => ({...prev, summary: false}))

                        // onContent({ title: 'swot', content: '' });

                        await runStreamingChatCompletionJSON(apiCall('/swot', {summary: productcnt}, false), {
                          setStream: setStream,
                          stepFunc: async (swotcnt, done) => {
                            // console.log('/swot done', done);
                            try {
                              const obj = partialParse(swotcnt);

                              // console.log('obj', obj);
                              if (done === true) {
                                // console.warn('Swot', swotcnt);
                                setCurState(prev => ({...prev, swot: false}));

                                // onContent({ title: 'Opportunity Plan', content: '' });

                                await runStreamingChatCompletion(apiCall('/oplan', {
                                  summary: productcnt,
                                  swot: swotcnt
                                }, false), {
                                  setStream: setStream,
                                  stepFunc: async (oplancnt, done) => {
                                    // console.log('/oplan done', done);
                                    try {
                                      if (done === true) {
                                        // console.warn('Oplan', oplancnt);
                                        setCurState(prev => ({...prev, oplan: false}));
                                        // onContent({ title: '3d room', content: '' });

                                        const swot = JSON.parse(swotcnt);

                                        // console.warn('PARSED_SWOT', swot);
                                        const res = await apiCall('/roomToken', {
                                          logo: cnt,
                                          summary: productcnt,
                                          strengths: swot['strengths'] || [],
                                          weaknesses: swot['weaknesses'] || [],
                                          opportunities: swot['opportunities'] || [],
                                          threats: swot['threats'] || [],
                                          oplan: oplancnt,
                                          location: 'NewLobby'
                                        });

                                        // console.warn('GET_TOKEN', res);
                                        localStorage.setItem('room-token', res.room);
                                        localStorage.setItem('token', res.auth);

                                        const cloudData = await client.login(res.auth);

                                        if (cloudData !== null) {
                                          const room = await client.createRoom({
                                            "location": "NewLobby",
                                            "title": localStorage.getItem(ROOM_TITLE_KEY) || 'Swot',
                                            "token": res.room
                                          });

                                          // console.warn('room ', room);
                                          if (room && room.code) {
                                            const roomLink = `${process.env.REACT_APP_BR_HOST}/#${room.code}`;
                                            // console.warn('ROOM LINK', roomLink);

                                            appContext.setRoomLink(roomLink);
                                            setCurState(prev => ({...prev, room: false}));
                                          } else {
                                            console.error('Failed to get room code');
                                            handleError('System error. Failed to create room');
                                          }
                                        } else {
                                          console.error('Failed to login in cloud');
                                          handleError('System error. Failed to create room.');
                                        }
                                      }
                                    } catch (e) {
                                      console.error(e);
                                      handleError('System error. Try again later.');
                                    }
                                  },
                                });
                              }
                            } catch (e) {
                              console.error(e);
                              handleError('System error. Try again later.');
                            }
                          },
                        });
                      }
                    } catch (e) {
                      console.error(e);
                      handleError('System error. Try again later.');
                    }
                  },
                });
              }
            } catch (e) {
              console.error(e);
              handleError('System error. Try again later.');
            }
          },
        });
      } catch (e) {
        console.error(e);
        handleError('System error. Try again later.');
      }
    }

    if (!started) {
      pushDataLayer({
        event: "swot.generator_start",
        id: appContext.analyticsId,
      });
      setStarted(true);
      run();
    }
  }, [started, appContext]);

  return (
    <>
      <div className="row align-items-sm-center mb-5">
        <div className="col-sm">
          <h5 className="card-title">{!appContext.roomLink ? 'AI is researching the market ...' : 'Done'}</h5>
          <p className="card-text" style={{ color: '#616161', fontSize: '14px', lineHeight: '16.4px', fontWeight: '400' }}>It may take a few minutes. Please do not leave the page.</p>
          <p className="card-text" style={{ marginTop: '16px', minHeight: '24px' }}>{!appContext.roomLink ? stream : '3D space is ready'}</p>
        </div>
      </div>

      <div>

        <div>
          <GeneratorItem
            title="Logo"
            description="Checking site data"
            isLoading={curState.logo}
          />
          <GeneratorItem
            title="Summary"
            description="Researching your product"
            isLoading={curState.summary}
          />
          <GeneratorItem
            title="SWOT"
            description="Researching the market"
            isLoading={curState.swot}
          />
          <GeneratorItem
            title="Opportunity Plan"
            description="Researching the market"
            isLoading={curState.oplan}
          />
          <GeneratorItem
            className="mb-0"
            title="3D space"
            description="Preparing 3D space"
            isLoading={curState.room}
          />
        </div>

        {appContext.roomLink && <div className="d-flex align-items-center justify-content-center mt-7">
          <button onClick={() => {
            pushDataLayer({
              event: "swot.clickVisit",
              id: appContext.analyticsId,
              link: appContext.roomLink,
            });
            window.open(appContext.roomLink);
          }} className="cursor-pointer justify-content-center mb-s0 w-100 btn submit-main-btn btn-lg d-flex">
            Go To 3D space
          </button>
        </div>}
      </div>
    </>
  )
}