/* eslint-disable jsx-a11y/img-redundant-alt */
import React, {useEffect, useState} from 'react';
import {useAppContext} from "../context/app";
import {RegisterForm} from "./RegisterForm";
import {Generator} from "./Generator";
import {publicAsset} from "../utils/publicAsset";
import { pushDataLayer } from '../utils/ga';

export function App () {
  const appContext = useAppContext();
  const [sentSuccess, setSentSuccess] = useState(false);
  const [content, setContent] = useState(null);

  useEffect(() => {
    // @ts-ignore
    HSCore.components.HSTyped.init('.js-typedjs');
    // @ts-ignore
    var sliderThumbs = new Swiper('.js-swiper-thumbs', {
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      history: false,
      breakpoints: {
        480: {
          slidesPerView: 2,
          spaceBetween: 15,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 15,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 15,
        },
      },
      on: {
        'afterInit': function (swiper) {
          swiper.el.querySelectorAll('.js-swiper-pagination-progress-body-helper')
          .forEach($progress => $progress.style.transitionDuration = `${swiper.params.autoplay.delay}ms`)
        }
      }
    });

    // @ts-ignore
    var sliderMain = new Swiper('.js-swiper-main', {
      effect: 'fade',
      autoplay: true,
      loop: true,
      thumbs: {
        swiper: sliderThumbs
      }
    })
  }, []);
  const titleText = (() => {
    if (appContext.route === 'generator') {
      return (
        <div className="col-sm">
          <h5 className="card-title">AI is researching the market...</h5>
          <p className="card-text">It may take a few minutes. Please do not leave the page.</p>
        </div>
      )
    }

    if (appContext.route === 'success') {
      if (!sentSuccess) {
        pushDataLayer({
          event: "swot.showSuccessPage",
          id: appContext.analyticsId,
          link: appContext.roomLink,
        });
        setSentSuccess(true);
      }

      return (
        <div className="col-sm">
          <h5 className="card-title">Room is ready</h5>
          <a href={appContext.roomLink} className="card-text">{appContext.roomLink}</a>
        </div>
      )
    }
    return (
      <div className="col-sm">
        <h5 className="card-title">Auto Generate Your Immersive 3D Strategy Space</h5>
        <p className="card-text">
        To generate your own private immersive 3D space – fill in the below and link your company’s main landing page or product offering’s URL (http or https)
        </p>
      </div>
    )
  })();


  const swiperSection = (() => {
    return (
      <div className="position-relative">
      <div className="container">
        
        <div className="w-lg-65 text-center mx-lg-auto mb-7">
          <h3 className="text-black">One click - 3 Outcomes</h3>
          <p className="text-black">The outcomes that you will receive</p>
        </div>
        
        <div className="js-swiper-main swiper">
          <div className="swiper-wrapper" style={{ marginBottom: '40px' }}>
            <figure className="swiper-slide text-center">          
              <img style={{ maxHeight: '500px' }} className="device-browser-img" src={publicAsset("pics/swot.jpg")} alt="null" /> 
            </figure>

            <figure className="swiper-slide text-center">          
              <img style={{ maxHeight: '500px' }} className="device-browser-img" src={publicAsset("pics/oplan.jpg")} alt="null" /> 
            </figure>

            <figure className="swiper-slide text-center">          
              <img style={{ maxHeight: '500px' }} className="device-browser-img" src={publicAsset("pics/3d_space.jpg")} alt="null" /> 
            </figure>
          </div>
        </div>
                
        <div className="js-swiper-thumbs swiper">
          <div className="swiper-wrapper">
            
            <div className="swiper-slide swiper-pagination-progress swiper-pagination-progress">
              <div className="swiper-pagination-progress-body mb-4">
                <div className="js-swiper-pagination-progress-body-helper swiper-pagination-progress-body-helper swiper-pagination-progress-body-helper-dark"></div>
              </div>
              <h5 className="text-black">SWOT Analysis</h5>
              <span className="text-black">Auto generate a detailed market analysis for your company</span>
            </div>
            
            <div className="swiper-slide swiper-pagination-progress swiper-pagination-progress">
              <div className="swiper-pagination-progress-body mb-4">
                <div className="js-swiper-pagination-progress-body-helper swiper-pagination-progress-body-helper swiper-pagination-progress-body-helper-dark"></div>
              </div>
              <h5 className="text-black">Opportunity Plan</h5>
              <span className="text-black">Auto generate an action plan to strengthen your position</span>
            </div>
            
            
            <div className="swiper-slide swiper-pagination-progress swiper-pagination-progress">
              <div className="swiper-pagination-progress-body mb-4">
                <div className="js-swiper-pagination-progress-body-helper swiper-pagination-progress-body-helper swiper-pagination-progress-body-helper-dark"></div>
              </div>
              <h5 className="text-black">3D Space</h5>
              <span className="text-black">Auto generate a permanent 3D immersive space to share & revisit with your plan and work in one place</span>
            </div>
            
          </div>
        </div>
        
      </div>

      
      <div className="lines lines-light">
        <div className="lines-container">
          <div className="lines-item"></div>
          <div className="lines-item"></div>
          <div className="lines-item"></div>
          <div className="lines-item"></div>
          <div className="lines-item"></div>
        </div>
      </div>
      
    </div>
    )
  })();
  return (
    <div>
      <header id="header" className="navbar navbar-expand-lg navbar-light navbar-end bg-main-dark-bg">
        <div className="container">
          <nav className="js-mega-menu navbar-nav-wrap" suppressHydrationWarning={true}>

            <div className="navbar-brand">
              <img className="navbar-brand-logo" src={publicAsset("brlogo.png")} alt="Beyond real logo"/>
            </div>

            <div className="ms-auto">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a className="nav-link" href="https://beyondreal.life">Beyondreal</a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>

      <main id="content" role="main">

        <div className="bg-main-dark-bg position-relative overflow-hidden">
          <div className="container content-space-2">
            <div className="row justify-content-center align-items-lg-center">
              <div className="col-xs-12 col-lg-6 mb-7 mb-lg-0">

                <div className="pe-lg-3 mb-7">
                  <h1 className="display-3 text-white mb-3 mb-md-5" style={{fontSize: "3.5rem"}}><span>Dive into your dedicated&nbsp;</span><span className="color-main-gold">3D Immersive Space</span>&nbsp;<span>with your</span>&nbsp;<span className="color-main-gold">OWN strategy</span>
                  {/* &nbsp;
                    <span className="color-main-gold"><span className="js-typedjs" data-hs-typed-options='{
                    "strings": ["strategy", "SWOT", "plan"],
                    "typeSpeed": 90,
                    "loop": true,
                    "backSpeed": 30,
                    "backDelay": 2500
                  }'></span></span> */}
                  </h1>
                  <p className="lead text-white-70">Let our AI assistant conduct a powerful SWOT analysis on your company while placing the detailed plan inside your own personally branded 3D Space</p>

                  <a className="btn primary-btn mt-4" style={{ minWidth: '191px' }} href="#form">Create Strategy</a>
                </div>

              </div>

              <div className="col-xs-12 col-lg-6 text-center">
              <img className="img-fluid" src={publicAsset("pics/main2.jpg")} alt="null"/>
              </div>

            </div>
          </div>

        </div>
      </main>

      <div className="container content-space-2">
      <div>

            {swiperSection}
      </div>
      </div>

      <div id="form" className="d-flex flex-column justify-content-center align-items-center  bg-orange-light" style={{ paddingBottom: '100px', paddingTop: '100px' }}>
      <h3>Visualize your Strategy</h3>
<div className="mt-5 mb-5 card card-lg w-100" style={{ maxWidth: '590px' }}>
  <div className="card-body">
    {appContext.route !== 'generator' && <div className="row align-items-sm-center mb-5">
      {titleText}
    </div>}
    {appContext.route === 'auth' && <RegisterForm/>}
    {appContext.route === 'generator' && <Generator
    // onContent={(data) => {
    //   setContent(data);
    // }}
    />}
    {appContext.roomLink && appContext.route === 'success' && <div>
      {appContext.roomLink && <div className="d-flex align-items-center justify-content-center mt-7">
        <a href={appContext.roomLink}
           className="cursor-pointer justify-content-center w-100 btn submit-main-btn btn-lg d-flex">
          Go To 3D space
        </a>
      </div>}
    </div>}
  </div>

  {/* {content && <div>
    <p>{content.title}</p>
    <p>{content.content}</p>  
  </div>} */}
</div>
      </div>
      
      <div className="container content-space-1 content-space-md-3">
        <div className="banner-start" style={{ marginBottom: '100px' }}>
          <div className="row gx-0 align-items-md-center mx-5">
            <div className="col-md-6">
              <div className="pe-5 pt-5 pb-5 pe-md-7 pt-md-7 pb-md-7">
                <div className="mb-7" style={{ maxWidth: '459px' }}>
                  <span className="text-cap text-gray900">About us</span>
                  <h3 className="text-gray900">BeyondReal – The Global Leader in Spatial Collaboration</h3>
                  <p className="text-gray900" style={{ marginTop: '16px' }}>
                  Our game changing immersive 3D spaces can be accessed from any device either through a Web Browser and in Virtual Reality.
                  <br />
                  <br />
                  The AI assisted auto multi-language translation and transcription allows you to communicate smoothly while allowing AI to generate automatic summaries , follow ups and suggestions.
                  <br />
                  <br />
                  As for the platform itself, we boast an array of tools for collaboration, such as whiteboarding, 3D object manipulation, multi-media screens, 3rd Party Integrations and many more.
                  </p>
                </div>

                <p>Experience it now for free at <a className="color-main-gold" rel="noopener noreferrer" target="_blank"
                                                href="https://beyondreal.life">beyondreal.life</a></p>
              </div>
            </div>

            <div className="col-md-6">
              <div className="w-85 w-md-100 mx-auto me-md-auto">
                <img className="img-fluid" src={publicAsset("br_case.png")} alt="null"/>
              </div>
            </div>
          </div>
        </div>

                <div className="mx-auto" style={{maxWidth: '31.25rem' }}>
          <div className="alert bg-orange-light" role="alert">
            <div className="d-sm-flex align-items-sm-center">
              <div className="flex-shrink-0 mb-3 mb-sm-0">
                <span className="svg-icon text-primary">
                  <img width="24" src={publicAsset("illu_thunder.svg")} alt="null"/>
                </span>
              </div>

              <div className="flex-grow-1 text-dark ms-sm-3 mb-3 mb-sm-0">Empowering independent business owners
                everywhere
              </div>

              <div className="flex-shrink-0 ms-sm-3">
                <a className="btn submit-main-btn" href="#form">Create Strategy</a>
              </div>
            </div>
          </div>
        </div>
</div>
      <footer className="bg-main-dark-bg">
        <div className="container">
          <div className="row content-space-1">
            <div className="col-lg-3 mb-7 mb-lg-0">
              <div className="mb-5">
                <a className="navbar-brand" href="https://beyondreal.life" aria-label="Logo">
                  <img className="navbar-brand-logo" src={publicAsset('brlogo.png')} alt="Logo"/>
                </a>
              </div>
            </div>


            <div className="col-sm d-flex justify-content-lg-end">
              <div>
                <span className="text-cap color-main-gold">Company</span>


                <ul className="list-unstyled list-py-1 mb-0">
                  <li><a className="link-sm link-light" href="https://beyondreal.life/about">About</a></li>
                  <li><a className="link-sm link-light" href="https://beyondreal.life/terms-and-conditions/">Terms and conditions</a></li>
                  <li><a className="link-sm link-light" href="https://beyondreal.life/privacy-policy">Privacy policy</a></li>
                </ul>
              </div>
            </div>

          </div>


          <div className="border-top border-white-10"></div>

          <div className="row align-items-md-end py-5">
            <div className="col-md mb-3 mb-md-0">
              <p className="text-white mb-0">Copyright © 2023 Beyondreal</p>
            </div>

            <div className="col-md d-md-flex justify-content-md-end">

            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}