import React, { useRef } from 'react';
import {useAppContext} from "../context/app";
import { pushDataLayer } from '../utils/ga';

export function RegisterForm () {
  const appContext = useAppContext();

  // @ts-ignore
  const { errors, handleChange, handleSubmit } = appContext.registerForm;

  const formRef = useRef<HTMLFormElement>(null);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    pushDataLayer({
      event: "swot.clickCreate",
      id: appContext.analyticsId,
    });

    handleSubmit(e);
  }
  
  return (
    <form ref={formRef} onSubmit={onSubmit}>
      <div className="mb-4">
        <label className="form-label" htmlFor="auth-name">Your name</label>
        <input autoComplete="name" id="auth-name" name="name" type="text" className="form-control form-control-lg" onChange={handleChange}
               placeholder="Type your name"
        />
        {errors.name}
      </div>


      <div className="mb-4">
        <label className="form-label" htmlFor="auth-email">Your email</label>
        <input autoComplete="email" id="auth-email" type="email" className="form-control form-control-lg" name="email"
               onChange={handleChange}
               placeholder="Type your email"/>
        {errors.email}
      </div>


      <div className="mb-4">
        <label className="form-label" htmlFor="auth-password">Your password</label>
        <input autoComplete="password" id="auth-password" name="password" type="password" className="form-control form-control-lg"
               placeholder="Type your password" onChange={handleChange}
        />
        {errors.password}
      </div>

      <div className="mb-4">
        <label className="form-label" htmlFor="auth-link">Web URL</label>
        <input autoComplete="company-url" type="text" className="form-control form-control-lg" name="link"
               id="auth-link"
               placeholder="Link in format https://domain.com"
               onChange={handleChange}
        />
        {errors.link}
      </div>

      {errors && errors['general'] && <div className="mb-4">
        {errors['general']}
      </div> }
      <div className="d-grid text-center">
        <button type="submit" className="btn submit-main-btn btn-lg">
          CREATE NOW
        </button>
      </div>
    </form>
  )
}
