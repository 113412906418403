import React from 'react';

export function GeneratorItem ({ title, description, isLoading, className = 'mb-4' }) {
  return (
    <div className={`${className} d-flex align-items-center justify-content-between`}>
      <div>
        <p className="mb-0 fw-bold">
          {title}
        </p>
        <p className="mb-0">
          {description}
        </p>
      </div>
      {isLoading ? <div className="spinner-border text-black" role="status">
        <span className="visually-hidden">Loading...</span>
      </div> : <div style={{
        fontSize: '32px',
      }}><i className="text-primary bi bi-check-circle-fill"></i></div>}
    </div>
  );
}