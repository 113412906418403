import React, {createContext, useContext, useEffect, useState} from "react";
import type { ReactNode } from 'react';
import {useFormik} from "formik";
import * as yup from "yup";
import { pushDataLayer } from "../utils/ga";
import { generateToken } from "../utils/randomToken";

type TRoute = 'auth' | 'generator' | 'success';

export interface IAppContext {
  apiUrl: string;
  isClient: boolean;
  authToken: string;
  brToken: string;
  analyticsId: string;
  linkData: string;
  getLinkData: () => Promise<{data: string; error: string;}>;
  roomToken: string;
  roomLink: string;
  registerForm: any;
  setRoomLink: (roomLink: string) => void;
  route: TRoute;
  clearStorage: () => void;
  setRoute: (route: TRoute) => void;
  setLinkData: (linkData: string) => void;
  setAuthToken: (value: string) => void;
  setBrToken: (value: string) => void;
  setRoomToken: (value: string) => void;
}

const DEFAULT_APP_CONTEXT: IAppContext = {
  apiUrl: '',
  isClient: false,
  authToken: '',
  analyticsId: '',
  brToken: '',
  linkData: '',
  registerForm: {},
  getLinkData: () => Promise.resolve({ data: '', error: ''}),
  roomToken: '',
  roomLink: '',
  clearStorage: () => null,
  route: 'auth',
  setLinkData: () => null,
  setRoomLink: () => null,
  setRoute: () => null,
  setAuthToken: () => null,
  setBrToken: () => null,
  setRoomToken: () => null,
}

export function apiCall (uri: string, body: object, isJson: boolean = true) {
  const authToken = localStorage.getItem(AUTH_TOKEN_KEY) || DEFAULT_APP_CONTEXT.authToken;
  return fetch(`${process.env.REACT_APP_API_URL}${uri}`, {
    method: 'post',
    mode: "cors",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(body),
  }).then(r => isJson ? r.json() : r);
}

export const AppContext = createContext(DEFAULT_APP_CONTEXT);

export interface IAppContextProviderProps {
  children?: ReactNode;
}

export const AUTH_TOKEN_KEY = 'auth-token';
export const BR_TOKEN_KEY = 'token';
export const ROOM_TOKEN_KEY = 'room-token';
export const ROOM_LINK_KEY = 'br_room_link'
export const ANALYTICS_ID_KEY = 'analytics_id';
export const ROOM_TITLE_KEY = 'room-title';

export function AppContextProvider (props: IAppContextProviderProps) {
  const [analyticsId, setAnalyticsIdState] = useState(
    localStorage.getItem(ANALYTICS_ID_KEY) || generateToken(32)
  );
  const [route, setRoute] = useState(DEFAULT_APP_CONTEXT.route);
  const [isClient, setIsClient] = useState(DEFAULT_APP_CONTEXT.isClient);
  const [authToken, setAuthTokenState] = useState(
    localStorage.getItem(AUTH_TOKEN_KEY) || DEFAULT_APP_CONTEXT.authToken
  );
  const [brToken, setBrTokenState] = useState(
    localStorage.getItem(BR_TOKEN_KEY) || DEFAULT_APP_CONTEXT.brToken
  );
  const [roomToken, setRoomTokenState] = useState(
    localStorage.getItem(ROOM_TOKEN_KEY || DEFAULT_APP_CONTEXT.roomToken)
  );
  const [roomLink, setRoomLinkState] = useState(
    localStorage.getItem(ROOM_LINK_KEY || DEFAULT_APP_CONTEXT.roomLink)
  );
  const [linkData, setLinkData] = useState(DEFAULT_APP_CONTEXT.linkData);

  async function getLinkData () {
    try {
      const linkData = await apiCall('/linkData', {});

      if (!linkData || !linkData.content) {
        throw new Error('no-content');
      }

      return { data: linkData.content, error: '' };
    } catch (e) {
      if (e && e.message && e.message === 'no-content') {
        return { data: '', error: 'Cannot parse this link, try a different one' };
      } else {
        return { data: '', error: 'Unexpected server error. Try again' }
      }
    }
  }

  useEffect(() => {
    if (!localStorage.getItem(ANALYTICS_ID_KEY)) {
      localStorage.setItem(ANALYTICS_ID_KEY, analyticsId);
    }

    pushDataLayer({
      event: "swot.view",
      id: analyticsId,
      brRoomLink: roomLink,
      roomToken: roomToken,
    });

    // eslint-disable-next-line
  }, []);

  function setAuthToken (value: string) {
    localStorage.setItem(AUTH_TOKEN_KEY, value);
    setAuthTokenState(value);
  }

  function setRoomLink (value: string) {
    localStorage.setItem(ROOM_LINK_KEY, value);
    setRoomLinkState(value);
  }

  function setBrToken (value: string) {
    localStorage.setItem(BR_TOKEN_KEY, value);
    setBrTokenState(value);
  }

  function setRoomToken (value: string) {
    localStorage.setItem(ROOM_TOKEN_KEY, value);
  }

  function clearStorage () {
    localStorage.setItem(AUTH_TOKEN_KEY, null);
    localStorage.setItem(BR_TOKEN_KEY, null);
    localStorage.setItem(ROOM_TOKEN_KEY, null);
    localStorage.setItem(ROOM_LINK_KEY, null);
  }

  useEffect(() => {
    if (roomLink && route === 'auth') {
      setRoute('success');
    }
  }, [route, roomLink]);

  const ctx = {
    isClient,
    apiUrl: process.env.REACT_APP_API_URL,
    authToken,
    setAuthToken,
    brToken,
    route,
    setRoute,
    roomLink,
    linkData,
    setBrToken,
    setLinkData,
    clearStorage,
    getLinkData,
    setRoomLink,
    roomToken,
    setRoomToken,
  }

  const { setErrors, ...registerForm } = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
      link: '',
    },
    validationSchema: yup.object({
      name: yup.string().required("Name is required"),
      email: yup.string().required("Email is required").email("Email format is not correct"),
      password: yup
        .string()
        .required("Password is required")
        .min(8, "Password must contain at lease 8 characters"),
      link: yup.string().required("Link is required"),
    }),
    onSubmit: submit,
  })

  async function submit (values) {
    pushDataLayer({
      event: "swot.submitForm",
      id: analyticsId,
    });

    if (values.link.includes('http://') || values.link.includes('https://')) {
      try {
        const res = await apiCall(`/auth`, values);
  
        if (res && res.fail) {
          throw new Error('User already exists');
        }
  
        const token = res.token;
  
        if (!token) {
          throw new Error('Unexpected server error');
        }
  
        ctx.setAuthToken(token);
  
        const { data: linkData, error: linkDataError } = await ctx.getLinkData();
  
        if (linkDataError) {
          throw new Error(linkDataError);
        } else {
          localStorage.setItem(ROOM_TITLE_KEY, values.name);
          ctx.setLinkData(linkData);
          ctx.setRoute('generator');
        }
      } catch (e) {
        pushDataLayer({
          event: "swot.submitFailed",
          id: analyticsId,
          message: e && e.message ? e.message : 'Unknown error',
          errorObj: e,
        });
  
        ctx.clearStorage();
        if (e && e.message) {
          setErrors({ general: e.message });
        } else {
          setErrors({ general: 'Server error. Try again later.' })
        }
      }
    } else {
      pushDataLayer({
        event: "swot.formError.noHttp",
        id: analyticsId,
      });
      setErrors({ general: 'Please include protocol (http or https) to your link' });
    }
  }
  const [dirtySent, setDirtySent] = useState(false);

  if (!dirtySent) {
    if (registerForm.dirty) {
      pushDataLayer({
        event: "swot.inputTouched",
        id: analyticsId,
      });
      setDirtySent(true);
    }
  }

  return (
    <AppContext.Provider value={{ ...ctx, analyticsId, registerForm }}>
      {props.children}
    </AppContext.Provider>
  )
}

export const useAppContext = () => useContext(AppContext);
